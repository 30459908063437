<template>
  <div class="pay-result">
    <div style="padding: 70px 0 30px;" class="sctp-tc register-success-container">
      <i class="el-icon-success"></i>
      <div class="sctp-color-main">
        <div class="sctp-mar-b15">
          <span class="sctp-mar-r5">支付成功</span>
        </div>
        <router-link to="/user/order">
          <el-button type="primary">查看订单</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'payResult',
  data () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>
  /*@formatter:off*/
  .pay-result .el-icon-success{font-size: 100px;color: #F90;margin-bottom: 15px;}
  /*@formatter:on*/
</style>
